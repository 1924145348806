import './infrastructure.scss'

import { FC } from 'react'

const Infrastructure: FC = () => {
  return (
    <>
      <div id='infrastructure'>
        <h1>Infrastructure</h1>
        <div id='infrastructure-description'>
          <h2>
            The Camber solution enables spacecraft to deploy, and host diverse
            technologies within secure partitions on a common software platform
          </h2>
          <p>
            Adversarial threats are evolving at an increasingly rapid pace,
            driving the need for the United States armed forces to more swiftly
            develop and evolve space technologies. Camber Research has teamed
            with Inbound Research to offer a software infrastructure that
            enables Space Vehicles (SV) to securely deploy diverse software
            capabilities within secure partitions that can be tailored to the
            theater’s threat environment.
          </p>
        </div>

        <div className='infrastructure-section-container'>
          <div className='infrastructure-section'>
            <h3>No Impact to SV’s Primary Mission</h3>
            <p>
              An SV employing the Camber framework for the primary mission
              software has the capability to easily upload, run, and delete
              apps, to expand mission capabilities or to provide a rapid testbed
              for new space technologies. Diverse SW technologies on the SV, can
              ingest raw data from the SV and its sensors to execute algorithms
              that produce new data products to the warfighter or expand SV
              capabilities.
            </p>
          </div>
        </div>
        <div className='infrastructure-section-container'>
          <div className='infrastructure-section'>
            <h3>Streamlined Concept of Operations</h3>
            <p>
              This capability enables incremental testing and deployment of new
              sensor and SV software capabilities in a space environment to
              enhance mission performance.
              <ul>
                <li>Built on proven terrestrial cyber technologies</li>
                <li>
                  Develop/evolve new sensor processing algorithms in the space
                  environment in which they will be used
                </li>
                <li>
                  Perform onboard analytics/real-time tactical decision making
                  (tipping & queuing)
                </li>
              </ul>
            </p>
          </div>
        </div>
        <div className='infrastructure-section-container'>
          <div className='infrastructure-section'>
            <h3>Enhanced Security</h3>
            <p>
              New defense technologies can be safely run on commercial and other
              government SVs while providing the right security for the
              mission’s threat environment.
              <ul>
                <li>All apps reside with in secure partitions</li>
                <li>
                  Interfaces between partitions can be configured with
                  operator-defined encryption strategies
                </li>
                <li>
                  User defined threat mitigations can be uploaded based on the
                  evolving threat environment
                </li>
              </ul>
            </p>
          </div>
        </div>
        <div className='infrastructure-section-container'>
          <div className='infrastructure-section'>
            <h3>Multi-domain Operations</h3>
            <p>
              Government technologies can be safely run on commercial and civil
              SVs.
              <ul>
                <li>
                  Technologies developed for defense applications can be safely
                  run on commercial platforms
                </li>
                <li>The primary mission of the host SV is not interrupted</li>
                <li>
                  Common applications can be run on vehicles in space, in the
                  air, on land, or at sea
                </li>
              </ul>
            </p>
          </div>
        </div>
      </div>
    </>
  )
}

export default Infrastructure

import { Routes, Route } from 'react-router-dom'
import Home from './home/home'
import Services from './services/services'
import Approach from './approach/approach'
import Infrastructure from './infrastructure/infrastructure'
// import News from './news/news'
import About from './about/about'

const Router = () => {
  return (
    <Routes>
      <Route path='/' element={<Home />} />
      <Route path='/services' element={<Services />} />
      <Route path='/approach' element={<Approach />} />
      <Route path='/infrastructure' element={<Infrastructure />} />
      {/* <Route path='/news' element={<News />} /> */}
      <Route path='/about' element={<About />} />
    </Routes>
  )
}

export default Router

import './main-menu.scss'

import React from 'react'
import { Link, NavLink } from 'react-router-dom'
import CamberIcon from '../../../assets/imgs/Camber_Full_Logo/Camber_Logo.png'
import { useRefResize } from '../../../hooks/use-ref-resize'
import SiteBlocker from '../../site-blocker/site-blocker'

type MenuRoutes = {
  name: string
  path: string
}

const MOBILE_TRIGGER = 600

const MainMenu: React.FC = () => {
  const menuRef = React.useRef(null)
  const menuSize = useRefResize(menuRef)
  const [mobile, setMobile] = React.useState(true)
  const [menuToggled, setMenuToggled] = React.useState(false)
  // const [menuElem, setMenuElem] = React.useState<HTMLDivElement | null>(null)
  // const [routesElem, setRoutesElem] = React.useState<HTMLElement | null>(null)
  // const [mobile, setMobile] = React.useState(false)

  const routes: MenuRoutes[] = [
    {
      name: 'Services',
      path: '/services',
    },
    // {
    //   name: 'Compliance',
    //   path: '/compliance',
    // },
    {
      name: 'Approach',
      path: '/approach',
    },
    {
      name: 'Infrastructure',
      path: '/infrastructure',
    },
    // {
    //   name: 'News',
    //   path: '/news',
    // },
    {
      name: 'About',
      path: '/about',
    },
  ]

  React.useEffect(() => {
    if (menuSize.width) {
      setMobile(window.innerWidth <= MOBILE_TRIGGER)
    }
  }, [menuSize])

  React.useEffect(() => {
    if (mobile) {
      setMenuToggled(false)
    }
  }, [mobile])

  const toggleMenu = () => {
    setMenuToggled((prev) => !prev)
  }

  return (
    <>
      <div id='main-menu' ref={menuRef}>
        <Link to={'/'} id='site-icon'>
          <img src={CamberIcon} alt='site icon' />
        </Link>
        {mobile && (
          <svg
            id='main-menu-toggle'
            className={menuToggled ? 'toggled' : undefined}
            width='48'
            height='48'
            onClick={toggleMenu}
          >
            <rect x='4' y='10' width='40' height='4' rx='2' />
            <rect x='4' y='22' width='40' height='4' rx='2' />
            <rect x='4' y='34' width='40' height='4' rx='2' />
          </svg>
        )}
        <nav
          id='main-menu-routes'
          className={`${mobile ? 'mobile' : undefined} ${
            menuToggled ? 'toggled' : undefined
          }`}
          style={mobile ? { top: menuSize.height + 'px' } : {}}
        >
          <ul>
            {routes.map((route, ind) => (
              <li key={'menu-item-' + ind}>
                <NavLink to={route.path}>
                  <span>{route.name}</span>
                </NavLink>
              </li>
            ))}
          </ul>
        </nav>
      </div>
      {mobile && menuToggled && <SiteBlocker />}
    </>
  )
}

export default MainMenu
